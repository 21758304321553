
import 'slick-carousel';
$(function(){
    function sliderSetting(){
        var width = $(window).width();
        if(width <= 900){
            $('.slick-box').slick({
                arrows: true,
                autoplay: true,
                dots: true,
                autoplaySpeed: 4000,
                fade: false,
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: true,
                focusOnSelect: true,
                variableWidth: true
            });
        } else {
            $('.slick-box.slick-initialized').slick('unslick');
        }
    }

    sliderSetting();

    $(window).resize( function() {
        sliderSetting();
    });
    $('.home-slick').slick({
        centerMode: true,
        centerPadding: '10%',
        adaptiveHeight: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        speed: 300,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: true,
              centerMode: true,
              centerPadding: '20%',
              slidesToShow: 3
            }
          },
          {
            breakpoint: 575,
            settings: {
              arrows: true,
              centerMode: true,
              centerPadding: '20%',
              slidesToShow: 1
            }
          }
        ]
      });
  });